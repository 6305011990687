import React from "react"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import styled from "styled-components"
import JapanMap from "../images/japan.svg"
import HTMLCode from "../images/html-code.svg"
import Random from "../images/random.svg"

const Scene = styled.div`
position: relative;
  height: 100vh;
  width: 100vw;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

  h2 {
    letter-spacing: 0.5rem;
  }

  svg {
    height: 360px;
  }

  a {
    color: black !important;
  }

  a:hover {
    background-color: unset;
  }

  & > div {
    width: 33%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    a {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

    /* ----------------------------------------------
 * Generated by Animista on 2020-8-16 18:20:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    /**
 * ----------------------------------------
 * animation flip-vertical-right
 * ----------------------------------------
 */
    @keyframes flip-vertical-right {
      0% {
        transform: rotateY(0);
      }
      100% {
        transform: rotateY(180deg);
      }
    }
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
const ResumeLink = styled.div`
  svg {
    rect:not(.main),
    path {
      fill: black !important;
    }
  }

  svg:hover {
    path {
      fill: #e0242d !important;
    }
    g rect:not(.main) {
      animation: flip-vertical-right 0.7s
        cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
    }

    rect.red {
      fill: #e0242d !important;
    }
`

const Map = styled.div`
  svg {
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    #land {
      fill: transparent;
      stroke: black;
      stroke-width: 2;
      transition: all 0.7s ease;
    }

    #circle {
      fill: transparent;
      stroke: black;
      stroke-width: 1;
      transition: all 0.5s ease;
    }
  }

  svg:hover {
    cursor: pointer;
    // g #land {
    //   fill: url(#left-to-right);
    // }
    #circle {
      fill: #e0242d;
      stroke: 0;
    }

    #land {
      fill: black;
      stroke: 0;
    }
  }
`

const RandomLink = styled.div`
  svg {
    filter: grayscale(100);
    transition: filter 0.5s ease;
  }

  &:hover svg {
    filter: grayscale(0);
  }
`

const HomePage = () => {
  return (
    <Scene>
      <ResumeLink>
        <Link to="/resume/">
          <HTMLCode />
          <h2>
            <FormattedMessage id="Resume" />
          </h2>
        </Link>
      </ResumeLink>
      <Map>
        <Link to="/map/">
          <JapanMap />
          <h2>
            <FormattedMessage id="Map" />
          </h2>
        </Link>
      </Map>

      <RandomLink>
        <Link to="/random/">
          <Random />
          <h2>
            <FormattedMessage id="Random" />
          </h2>
        </Link>
      </RandomLink>
    </Scene>
  )
}

export default HomePage
